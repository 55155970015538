import * as React from 'react'
import * as Rebass from 'rebass'
import styled from '@emotion/styled'
import { FastField, Field, FieldArray, Formik } from 'formik'
import * as Yup from 'yup'
// import MaskedInput from 'react-text-mask';
import SignatureCanvas from 'react-signature-canvas'
import axios, { AxiosRequestConfig } from 'axios'
import { Link as RouterLink, GatsbyLinkProps, navigate } from 'gatsby'
import { LinkProps } from '@reach/router'
import ReactInputMask from 'react-input-mask'

import Button from '@material-ui/core/Button'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import FormGroup from '@material-ui/core/FormGroup'
import Checkbox from '@material-ui/core/Checkbox'
import Grid from '@material-ui/core/Grid'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem'
import * as Color from 'color'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import CircularProgress from '@material-ui/core/CircularProgress';

import {
  makeStyles,
  createStyles,
  Theme,
  WithStyles
} from '@material-ui/core/styles'

import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Link from '@material-ui/core/Link'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'

import Amplify, { Auth } from 'aws-amplify'

import aws4 from 'aws4'

Amplify.configure({
  Auth: {
    identityPoolId: 'us-east-1:d8c6ec12-4b8a-45d9-a624-e939c511d8fa',
    region: 'us-east-1',
    userPoolId: 'us-east-1_Lbu0RBnOL',
    userPoolWebClientId: '58nk7vvnol6pbl3o6l8vag7ae7'
  }
})

import { Layout } from '../../../components/layout'
import SEO from '../../../components/seo'
import ErrorFocus from '../../../components/error-focus'
import { withStyles } from '@material-ui/styles'

// const AddIcon = require('../../../assets/icons/ic_add_circle_outline_24px.svg')
// const DelIcon = require('../../../assets/icons/ic_remove_circle_outline_24px.svg')

const LinkComponent = React.forwardRef<
  HTMLAnchorElement,
  Omit<GatsbyLinkProps<{}>, 'ref'>
>((props, ref) => (
  // @ts-ignore
  <RouterLink innerRef={ref} {...props} />
))

const BACKGROUND_COLOR = '#F5F7FA'
const BORDER_COLOR = '#E4E7EB'

const HR = styled('div')({
  height: '1px',
  width: '100%',
  backgroundColor: BORDER_COLOR
})

const Wrapper = styled(Box)({
  backgroundColor: BACKGROUND_COLOR
})

const ValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(1)
    .required('Required'),
  lastName: Yup.string()
    .min(1)
    .required('Required'),
  dateOfBirth: Yup.string()
    .matches(/^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/i, {
      message: 'Should be in format DD/MM/YYYY'
    })
    .required('Required'),
  phone: Yup.string().required('Required'),
  email: Yup.string()
    .email('Must be a valid email address')
    .required('Required'),
  address: Yup.string().required('Required'),
  city: Yup.string().required('Required'),
  province: Yup.string().required('Required'),
  postalCode: Yup.string()
    .matches(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/, {
      message: 'Incorrect Format'
    })
    .required('Required'),
  prescriptionDrugs: Yup.string(),
  lastMedicalCheckUp: Yup.string(),
  changesInGeneralHealthInPastYear: Yup.string(),
  appointmentType: Yup.string().required('Required'),
  preferredContactMethod: Yup.string().oneOf(['Email', 'Phone', 'SMS']).required('Required')
})

const styles = (theme: Theme) =>
  createStyles({
    root: {
      position: 'fixed',
      bottom: 0,
      width: '100%',
      borderTop: '1px solid #efefef',
      zIndex: 999
    },
    icon: {
      // marginLeft: theme.spacing(1),
      // marginRight: theme.spacing(1),
      '&:first-child': {
        marginLeft: 0
      }
    },
    textField: {
      // marginLeft: theme.spacing(1),
      // marginRight: theme.spacing(1),
    },
    formControl: {
      // margin: theme.spacing(3),
    },
    container: {},
    link: {}
  })
interface TextMaskCustomProps {
  inputRef: (ref: HTMLInputElement | null) => void;
  mask: string;
  maskPlaceholder?: string;
  onChange?: any;
  value?: any;
}

interface drug {
  drugs: string[];
}

interface submitBtnStatus {
  status: boolean;
}

function DateInputMask(props: TextMaskCustomProps) {
  const { inputRef, ...other } = props;

  return (
    <ReactInputMask {...other} ref={(ref: any) => {
      inputRef(ref ? ref.inputElement : null);
    }} type="tel" mask="99/99/9999" maskPlaceholder="dd/mm/yyyy" onChange={props.onChange} value={props.value} />
  );
}

function PhoneInputMask(props: TextMaskCustomProps) {
  const { inputRef, ...other } = props;

  return (
    <ReactInputMask {...other} ref={(ref: any) => {
      inputRef(ref ? ref.inputElement : null);
    }} type="tel" mask="+1 (999) 999-9999" maskPlaceholder="DD/MM/YYYY" onChange={props.onChange} value={props.value} />
  );
}

// const firstLetter = /(?!.*[DFIOQU])[A-VXY]/i;
// const letter = /(?!.*[DFIOQU])[A-Z]/i;
// const digit = /[0-9]/;
// const PostalCodeMask = [firstLetter, digit, letter, " ", digit, letter, digit];

function PostalCodeInputMask(props: TextMaskCustomProps) {
  const { inputRef, ...other } = props;
  return (
    <ReactInputMask {...other} ref={(ref: any) => {
      inputRef(ref ? ref.inputElement : null);
    }} mask="a9a 9a9" maskPlaceholder="A1A 1A1" onChange={props.onChange} value={props.value} />
  );
}

/*
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
      showMask={true}
    />
*/

const ContentWrapper = withStyles((theme: Theme) => ({
  root: {
    backgroundColor: '#f5f7fa',
    marginTop: theme.spacing(1),
    paddingTop: theme.spacing(3)
  }
}))(Box)

const FormWrapper = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  }
}))(Paper)

const DarkenColor = Color('rgb(60, 137, 239)')
  .darken(0.1)
  .hsl()
  .toString()

// const BlueButton = withStyles((theme: Theme) => ({
//   root: {
//     // color: theme.palette.getContrastText('rgb(60, 137, 239)'),
//     color: '#fff !important',
//     // boxShadow: 'none',
//     paddingLeft: theme.spacing(5),
//     paddingRight: theme.spacing(5),
//     paddingTop: theme.spacing(2),
//     paddingBottom: theme.spacing(2),
//     marginTop: theme.spacing(4),
//     textTransform: 'none',
//     fontSize: 16,
//     padding: '6px 12px',
//     border: '1px solid',
//     lineHeight: 1.5,
//     backgroundColor: 'rgba(60, 137, 239)',
//     borderColor: 'rgba(60, 137, 239)',
//     boxShadow: '0px 3px 20px rgba(60, 137, 239, 0.5)',
//     [theme.breakpoints.down('sm')]: {
//       width: '100%'
//     },
//     // fontFamily: [
//     //   'Nunito',
//     //   // 'Avenir'
//     // ].join(','),
//     // fontWeight: 600,
//     '&:hover': {
//       backgroundColor: DarkenColor,
//       borderColor: DarkenColor
//     },
//     '&:active': {
//       boxShadow: 'none',
//       backgroundColor: DarkenColor,
//       borderColor: DarkenColor
//     },
//     '&:focus': {
//       // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
//     }
//   },
//   text: {
//     color: '#fff'
//   }
// }))(Button)

const BlueButton = withStyles((theme: Theme) => ({
  root: {
    // color: theme.palette.getContrastText('rgb(60, 137, 239)'),
    color: '#3c8aef !important',
    // boxShadow: 'none',
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginTop: theme.spacing(4),
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    backgroundColor: 'rgba(237,246,255, 0.5)',
    borderColor: 'rgba(60, 137, 239)',
    // boxShadow: '0px 3px 20px rgba(60, 137, 239, 0.5)',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    // fontFamily: [
    //   'Nunito',
    //   // 'Avenir'
    // ].join(','),
    // fontWeight: 600,
    '&:hover': {
      backgroundColor: 'rgba(72,177,251, 0.2)',
      borderColor: DarkenColor
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: 'rgb(237,246,255)',
      borderColor: DarkenColor
    },
    '&:focus': {
      // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    }
  },
  text: {
    color: '#3c8aef'
  }
}))(Button)

export default withStyles(styles)(({ classes }: WithStyles<typeof styles>) => {
  const dateOfBirthLabel = React.useRef<HTMLLabelElement>(null)
  const [dateOfBirthLabelWidth, setDateOfBirthLabelWidth] = React.useState(0);

  const phoneLabel = React.useRef<HTMLLabelElement>(null)
  const [phoneLabelWidth, setPhoneLabelWidth] = React.useState(0);

  const postalCodeLabel = React.useRef<HTMLLabelElement>(null)
  const [postalCodeLabelWidth, setPostalCodeLabelWidth] = React.useState(0);

  const preferredContactMethodLabel = React.useRef<HTMLLabelElement>(null)
  const [preferredContactMethodLabelWidth, setPreferredContactMethodLabelWidth] = React.useState(0);

  const provinceLabel = React.useRef<HTMLLabelElement>(null)
  const [provinceLabelWidth, setProvinceLabelWidth] = React.useState(0);

  const initialdrugs: drug = {
    drugs: [""]
  };
  const [drugList, setDrugList] = React.useState<drug | null>(initialdrugs);
  const addDrugInput = () => {
    setDrugList((drugList) => ({
      drugs: drugList.drugs.concat("")
    }))
  }
  const removeDrugInput = (index) => {
    setDrugList((drugList) => ({
      drugs: drugList.drugs.slice(0, index).concat(drugList.drugs.slice(index + 1, drugList.drugs.length))
    }))
  }

  const handleChangeOneDrug = (e, index) => {
    drugList.drugs[index] = e.target.value;
    setDrugList({
      drugs: drugList.drugs
    })
  }
  const initialSubmitStatus: submitBtnStatus = {
    status: false
  }
  const [submitStatus, setSubmitStatus] = React.useState<submitBtnStatus | null>(initialSubmitStatus);

  React.useEffect(() => {
    setDateOfBirthLabelWidth(dateOfBirthLabel.current!.offsetWidth);
    setPhoneLabelWidth(phoneLabel.current!.offsetWidth);
    setPostalCodeLabelWidth(postalCodeLabel.current!.offsetWidth);
    setPreferredContactMethodLabelWidth(preferredContactMethodLabel.current!.offsetWidth);
    setProvinceLabelWidth(provinceLabel.current!.offsetWidth);
  }, []);

  return (
    <Layout>
      <SEO title="New Patient Form" />

      <Container maxWidth="md">
        <Box my={3}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link component={LinkComponent} color="inherit" to="/en">
              Home
            </Link>
            <Link component={LinkComponent} color="inherit" to="/en/resources/">
              Resources
            </Link>
            <Typography color="textPrimary">Forms</Typography>
          </Breadcrumbs>
        </Box>

        <HR />

        <Box mt={3}>
          <Typography variant="h4" component="h1">
            New Patient Form
          </Typography>

          <Box my={4}>
            <Typography>
              Your cooperation in completing this questionnaire is essential to
              providing you with the highest standard of dental care. All
              information is strictly confidential and will remain with this
              office.
            </Typography>
          </Box>
        </Box>
      </Container>

      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          dateOfBirth: '',
          phone: '',
          email: '',
          address: '',
          city: '',
          province: '',
          postalCode: '',
          lastMedicalCheckUp: '',
          prescriptionDrugs: drugList.drugs,
          changesInGeneralHealthInPastYear: '',
          changesInGeneralHealthInPastYearDescription: '',
          appointmentType: '',
          appointmentTypeDescription: '',
          preferredContactMethod: '',
          guardianName: '',
          existingMedicalConditions: '',
          existingMedicalConditionsDescription: '',
          lastMedicalCheckup: '',
          allergies: '',
          allergiesDescription: '',
          adverseReactions: '',
          adverseReactionsDescription: '',
          heartOrStroke: '',
          heartOrStrokeDescription: '',
          heartProblems: '',
          heartProblemsDescription: '',
          prosthetics: '',
          prostheticsDescription: '',
          bloodDisorders: '',
          bloodDisordersDescription: '',
          hospitalized: '',
          hospitalizedDescription: '',
          pastAndExistingDisorders: '',
          pastAndExistingDisordersDescription: '',
          heriditaryIssues: '',
          heriditaryIssuesDescription: '',
          smoker: '',
          smokerDescription: '',
          breastfeeding: '',
          breastFeedingDescription: '',
          diseases: {}
        }}
        validateOnChange
        validationSchema={ValidationSchema}
        onSubmit={async (values, actions) => {
          // console.log('*** values', JSON.stringify(values))
          // window.alert(JSON.stringify(values));
          setSubmitStatus({
            status: true
          })
          values.prescriptionDrugs = drugList.drugs;
          const formId = 'ccads2342423'
          let request = {
            service: 'execute-api',
            host: 'api.ari.dental',
            method: 'POST',
            url: 'https://api.ari.dental/form/submit',
            path: '/form/submit',
            data: {
              formId,
              values
            },
            body: JSON.stringify({ formId, values }),
            region: 'us-east-1',
            headers: {
              'Content-Type': 'application/json'
            }
          }

          const {
            secretAccessKey,
            accessKeyId,
            sessionToken
          } = await Auth.currentCredentials()

          let signedRequest = aws4.sign(request, {
            secretAccessKey: secretAccessKey,
            accessKeyId: accessKeyId,
            sessionToken: sessionToken
          })

          signedRequest.headers['Content-Type'] = 'application/json'

          delete signedRequest.headers['Host']
          delete signedRequest.headers['Content-Length']

          await axios(signedRequest)

          actions.setSubmitting(false)
          navigate('/en/resources/forms/form-success')
        }}
        render={({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit
        }) => {
          // console.log('*** values', JSON.stringify(values))
          // console.log('*** errorrs', JSON.stringify(errors));
          const hasError = (el) => (touched[el] && errors[el])

          return (
            <ContentWrapper>
              <Box pb={3}>
                <Container maxWidth="md">

                  <FormWrapper>

                    <Box py={1}>
                      <Typography variant="h6" component="h3">
                        Basic Information
                    </Typography>
                    </Box>

                    <HR />

                    <Grid container spacing={3}>

                      <Grid item xs={12} md={4}>
                        <FormControl margin="normal" fullWidth={true} error={hasError('firstName')}>
                          <TextField
                            id="firstName"
                            label="First Name"
                            className={classes.textField}
                            value={values.firstName}
                            variant="outlined"
                            onChange={handleChange}
                            fullWidth={true}
                            error={hasError('firstName')}
                          />
                          <FormHelperText>{hasError('firstName') && `${errors.firstName}`}</FormHelperText>
                        </FormControl>

                      </Grid>

                      <Grid item xs={12} md={4}>
                        <FormControl margin="normal" fullWidth={true} error={hasError('lastName')}>
                          <TextField
                            id="lastName"
                            label="Last Name"
                            className={classes.textField}
                            value={values.lastName}
                            variant="outlined"
                            onChange={handleChange}
                            fullWidth={true}
                            error={hasError('lastName')}
                          />
                          <FormHelperText>{hasError('lastName') && `${errors.lastName}`}</FormHelperText>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <FormControl margin="normal" fullWidth={true} error={hasError('dateOfBirth')}>
                          <InputLabel ref={dateOfBirthLabel} htmlFor="dateOfBirth" variant='outlined'>Date of Birth</InputLabel>
                          <OutlinedInput
                            id="dateOfBirth"
                            value={values.dateOfBirth}
                            onChange={handleChange}
                            error={hasError('dateOfBirth')}
                            inputComponent={DateInputMask}
                            fullWidth={true}
                            labelWidth={dateOfBirthLabelWidth}
                          />
                          <FormHelperText>{hasError('dateOfBirth') && `${errors.dateOfBirth}`}</FormHelperText>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} md={4}>

                        <FormControl margin="normal" fullWidth={true} error={hasError('phone')}>
                          <InputLabel ref={phoneLabel} htmlFor="phone" variant='outlined'>Phone</InputLabel>
                          <OutlinedInput
                            id="phone"
                            value={values.phone}
                            onChange={handleChange}
                            error={hasError('phone')}
                            inputComponent={PhoneInputMask}
                            fullWidth={true}
                            labelWidth={phoneLabelWidth}
                          />
                          <FormHelperText>{hasError('phone') && `${errors.phone}`}</FormHelperText>
                        </FormControl>

                      </Grid>

                      <Grid item xs={12} md={4}>
                        <FormControl error={hasError('email')} margin="normal" fullWidth={true}>
                          <TextField
                            id="email"
                            label="Email"
                            placeholder="email@example.com"
                            className={classes.textField}
                            value={values.email}
                            variant="outlined"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            fullWidth={true}
                            error={hasError('email')}
                          />
                          <FormHelperText>{hasError('email') && `${errors.email}`}</FormHelperText>
                        </FormControl>

                      </Grid>

                      <Grid item xs={12} md={4}>
                        <FormControl variant="outlined" margin="normal" fullWidth={true} className={classes.formControl} error={hasError('preferredContactMethod')}>
                          <InputLabel ref={preferredContactMethodLabel} htmlFor="preferredContactMethod" error={hasError('preferredContactMethod')}>
                            Preferred Contact Method
                        </InputLabel>
                          <Select
                            value={values.preferredContactMethod}
                            onChange={handleChange}
                            labelWidth={preferredContactMethodLabelWidth}
                            error={hasError('preferredContactMethod')}
                            inputProps={{
                              name: 'preferredContactMethod',
                              id: 'preferredContactMethod',
                            }}
                          >
                            <MenuItem value={'SMS'}>SMS</MenuItem>
                            <MenuItem value={'Email'}>Email</MenuItem>
                            <MenuItem value={'Phone'}>Phone</MenuItem>

                          </Select>
                          <FormHelperText>{hasError('preferredContactMethod') && `${errors.preferredContactMethod}`}</FormHelperText>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12}>
                        <FormControl margin="normal" fullWidth={true} error={hasError('address')}>
                          <TextField
                            id="address"
                            label="Address"
                            className={classes.textField}
                            value={values.address}
                            variant="outlined"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            fullWidth={true}
                            error={hasError('address')}
                          />
                          <FormHelperText>{hasError('address') && `${errors.address}`}</FormHelperText>
                        </FormControl>

                      </Grid>

                      <Grid item xs={12} md={4}>
                        <FormControl margin="normal" fullWidth={true} error={hasError('city')}>
                          <TextField
                            id="city"
                            label="City"
                            className={classes.textField}
                            value={values.city}
                            variant="outlined"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            fullWidth={true}
                            error={hasError('city')}
                          />
                          <FormHelperText>{hasError('city') && `${errors.city}`}</FormHelperText>
                        </FormControl>

                      </Grid>

                      <Grid item xs={6} md={4}>

                        <FormControl variant="outlined" margin="normal" fullWidth={true} className={classes.formControl} error={hasError('province')}>
                          <InputLabel ref={provinceLabel} htmlFor="province">
                            Province
                        </InputLabel>
                          <Select
                            value={values.province}
                            onChange={handleChange}
                            labelWidth={provinceLabelWidth}
                            error={hasError('province')}
                            inputProps={{
                              name: 'province',
                              id: 'province',
                            }}
                          >
                            <MenuItem value={'AB'}>Alberta</MenuItem>
                            <MenuItem value={'BC'}>British Columbia</MenuItem>
                            <MenuItem value={'MB'}>Manitoba</MenuItem>
                            <MenuItem value={'NB'}>New Brunswick</MenuItem>
                            <MenuItem value={'NL'}>Newfoundland and Labrador</MenuItem>
                            <MenuItem value={'NS'}>Nova Scotia</MenuItem>
                            <MenuItem value={'ON'}>Ontario</MenuItem>
                            <MenuItem value={'PE'}>Prince Edward Island</MenuItem>
                            <MenuItem value={'QC'}>Quebec</MenuItem>
                            <MenuItem value={'SK'}>Saskatchewan</MenuItem>

                          </Select>
                          <FormHelperText>{hasError('province') && `${errors.province}`}</FormHelperText>
                        </FormControl>


                      </Grid>

                      <Grid item xs={6} md={4}>

                        <FormControl margin="normal" fullWidth={true} error={hasError('postalCode')}>
                          <InputLabel ref={postalCodeLabel} htmlFor="postalCode" variant='outlined'>Postal Code</InputLabel>
                          <OutlinedInput
                            id="postalCode"
                            placeholder="A1A 1A1"
                            value={values.postalCode}
                            onChange={handleChange}
                            error={hasError('postalCode')}
                            inputComponent={PostalCodeInputMask}
                            fullWidth={true}
                            labelWidth={postalCodeLabelWidth}
                          />
                          <FormHelperText>{hasError('postalCode') && `${errors.postalCode}`}</FormHelperText>
                        </FormControl>

                      </Grid>

                      <HR />

                      <Grid item xs={12}>
                        <FormControl
                          component="fieldset"
                          className={classes.formControl}
                          fullWidth={true}
                          error={hasError('appointmentType')}
                        >
                          {/* <FormLabel component="legend">
                        Type of Appointment
                      </FormLabel> */}
                          <Box py={1}>
                            <Typography variant="h6" component="h3">
                              Type of Appointment
                        </Typography>
                          </Box>

                          <Box py={3}>
                            <RadioGroup
                              aria-label='appointment type'
                              name="appointmentType"
                              value={values.appointmentType}
                              onChange={handleChange}
                              row={true}
                              onBlur={handleBlur}
                            >
                              <Grid item xs={12} md={3}>
                                <FormControlLabel
                                  labelPlacement="end"
                                  value="New Patient"
                                  control={<Radio />}
                                  label="New Patient"
                                />
                              </Grid>

                              <Grid item xs={12} md={3}>
                                <FormControlLabel
                                  labelPlacement="end"
                                  value="Emergency"
                                  control={<Radio />}
                                  label="Emergency"
                                />
                              </Grid>

                              <Grid item xs={12} md={3}>
                                <FormControlLabel
                                  labelPlacement="end"
                                  value="Consultation"
                                  control={<Radio />}
                                  label="Consultation"
                                />
                              </Grid>


                              <Grid item xs={12} md={3}>
                                <FormControlLabel
                                  labelPlacement="end"
                                  value="Other"
                                  control={<Radio />}
                                  label="Other"
                                />
                              </Grid>
                            </RadioGroup>


                            {values.appointmentType === 'Other' && (
                              <Box p={2}>
                                <TextField
                                  id="appointmentTypeDescription"
                                  label="Explain the nature of the visit"
                                  className={classes.textField}
                                  margin="normal"
                                  variant="filled"
                                  fullWidth={true}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.appointmentTypeDescription}
                                  multiline={true}
                                  rows={4}
                                />
                              </Box>
                            )}

                          </Box>

                        </FormControl>
                      </Grid>


                      <HR />

                      <Box p={2}>

                        <Typography variant="h6" component="h3">Health History</Typography>

                      </Box>
                      <Box pt={0} pb={2} pl={2} pr={2}>
                        <Typography>
                          Please fill out this section to the best of your knowledge.
                          It is important for us to be aware of any health issues that
                          may affect the treatment you receive from our office. This
                          information is kept strictly confidential.
                      </Typography>
                      </Box>

                      <Box p={2}>
                        <FormControl
                          component="fieldset"
                          className={classes.formControl}
                          fullWidth={true}
                        >
                          <FormLabel component="legend">
                            Are you being treated for any medical condition at present
                            or within the past year?
                    </FormLabel>
                          <RadioGroup
                            aria-label="existing medical conditions"
                            name="existingMedicalConditions"
                            value={values.existingMedicalConditions}
                            onChange={handleChange}
                            row={true}
                            onBlur={handleBlur}
                          >
                            <FormControlLabel
                              labelPlacement="end"
                              value="Yes"
                              control={<Radio />}
                              label="Yes"
                            />
                            <FormControlLabel
                              labelPlacement="end"
                              value="No"
                              control={<Radio />}
                              label="No"
                            />
                            <FormControlLabel
                              labelPlacement="end"
                              value="Maybe/Not Sure"
                              control={<Radio />}
                              label="Maybe/Not Sure"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>


                      {(values.existingMedicalConditions === 'Yes'
                        || values.existingMedicalConditions === 'Maybe/Not Sure') && (
                          <Grid item xs={12}>
                            <TextField
                              id="existingMedicalConditionsDescription"
                              label="Please Explain"
                              className={classes.textField}
                              margin="normal"
                              variant="outlined"
                              fullWidth={true}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.existingMedicalConditionsDescription}
                            />
                          </Grid>
                        )}

                      <Grid item xs={12}>
                        <TextField
                          id="lastMedicalCheckUp"
                          label="When was your last medical check up"
                          className={classes.textField}
                          value={values.lastMedicalCheckUp}
                          margin="normal"
                          variant="outlined"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          fullWidth={true}
                        />
                      </Grid>

                      {/* <TextField
                    id="changesInGeneralHealthInPastYear"
                    label="Have there been any changes in your general health in the past year?"
                    className={classes.textField}
                    value={values.changesInGeneralHealthInPastYear}
                    margin="normal"
                    variant="outlined"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    fullWidth={true}
                  /> */}

                      <Box p={2}>

                        <FormControl
                          component="fieldset"
                          className={classes.formControl}
                          fullWidth={true}
                        >
                          <FormLabel component="legend">
                            Have there been any changes in your general health in the past year?
                    </FormLabel>
                          <RadioGroup
                            aria-label="changesInGeneralHealthInPastYear"
                            name="changesInGeneralHealthInPastYear"
                            value={values.changesInGeneralHealthInPastYear}
                            onChange={handleChange}
                            row={true}
                            onBlur={handleBlur}
                          >
                            <FormControlLabel
                              labelPlacement="end"
                              value="Yes"
                              control={<Radio />}
                              label="Yes"
                            />
                            <FormControlLabel
                              labelPlacement="end"
                              value="No"
                              control={<Radio />}
                              label="No"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>

                      {values.changesInGeneralHealthInPastYear === 'Yes'
                        && (
                          <Grid item xs={12}>
                            <TextField
                              id="changesInGeneralHealthInPastYearDescription"
                              label="Please Explain"
                              className={classes.textField}
                              margin="normal"
                              variant="outlined"
                              fullWidth={true}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.changesInGeneralHealthInPastYearDescription}
                            />
                          </Grid>
                        )}

                      <Grid item xs={12}>
                        <FormLabel component="legend">
                          List any prescription or non-prescription drugs you are taking or have recently taken including birth control pills.
                    </FormLabel>
                      </Grid>
                      {
                        drugList.drugs.map((drug, index) => {
                          return (
                            <React.Fragment key={index}>
                              <Grid item xs={11}>
                                <TextField
                                  id={index.toString()}
                                  className={classes.textField}
                                  value={drug}
                                  margin="normal"
                                  variant="outlined"
                                  onBlur={handleBlur}
                                  onChange={(e) => handleChangeOneDrug(e, index)}
                                  fullWidth={true}
                                />
                              </Grid>
                              <Grid item xs={1} style={{ paddingTop: "40px" }}>
                                <AddCircleOutlineIcon onClick={addDrugInput} />
                                <RemoveCircleOutlineIcon onClick={() => removeDrugInput(index)} />
                              </Grid>
                            </React.Fragment>
                          )
                        })
                      }



                      {/* <TextField
                    id="allergies"
                    label="Do you have any allergies?"
                    className={classes.textField}
                    value={values.allergies}
                    margin="normal"
                    variant="outlined"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    fullWidth={true}
                  /> */}

                      <Box p={2}>

                        <FormControl
                          component="fieldset"
                          className={classes.formControl}
                          fullWidth={true}
                        >
                          <FormLabel component="legend">
                            Do you have any allergies?
                    </FormLabel>
                          <RadioGroup
                            aria-label="allergiess"
                            name="allergies"
                            value={values.allergies}
                            onChange={handleChange}
                            row={true}
                            onBlur={handleBlur}
                          >
                            <FormControlLabel
                              labelPlacement="end"
                              value="Yes"
                              control={<Radio />}
                              label="Yes"
                            />
                            <FormControlLabel
                              labelPlacement="end"
                              value="No"
                              control={<Radio />}
                              label="No"
                            />
                            <FormControlLabel
                              labelPlacement="end"
                              value="Not Sure"
                              control={<Radio />}
                              label="Not Sure"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>

                      {(values.allergies === 'Yes' || values.allergies === 'Not Sure')
                        && (
                          <Grid item xs={12}>
                            <TextField
                              id="allergiesDescription"
                              label="Please Explain"
                              className={classes.textField}
                              margin="normal"
                              variant="outlined"
                              fullWidth={true}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.allergiesDescription}
                            />
                          </Grid>
                        )}

                      {/* <TextField
                    id="adverseReactions"
                    label="Have you ever had a peculiar or adverse reaction to any medicines or injections?"
                    className={classes.textField}
                    value={values.adverseReactions}
                    margin="normal"
                    variant="outlined"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    fullWidth={true}
                  /> */}

                      <Box p={2}>

                        <FormControl
                          component="fieldset"
                          className={classes.formControl}
                          fullWidth={true}
                        >
                          <FormLabel component="legend">
                            Have you ever had a peculiar or adverse reaction to any medicines or injections?
                    </FormLabel>
                          <RadioGroup
                            aria-label="adverseReactions"
                            name="adverseReactions"
                            value={values.adverseReactions}
                            onChange={handleChange}
                            row={true}
                            onBlur={handleBlur}
                          >
                            <FormControlLabel
                              labelPlacement="end"
                              value="Yes"
                              control={<Radio />}
                              label="Yes"
                            />
                            <FormControlLabel
                              labelPlacement="end"
                              value="No"
                              control={<Radio />}
                              label="No"
                            />
                            <FormControlLabel
                              labelPlacement="end"
                              value="Not Sure"
                              control={<Radio />}
                              label="Not Sure"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>

                      {(values.adverseReactions === 'Yes' || values.adverseReactions === 'Not Sure')
                        && (
                          <Grid item xs={12}>
                            <TextField
                              id="adverseReactionsDescription"
                              label="Please Explain"
                              className={classes.textField}
                              margin="normal"
                              variant="outlined"
                              fullWidth={true}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.adverseReactionsDescription}
                            />
                          </Grid>
                        )}

                      {/* <TextField
                    id="heartOrStroke"
                    label="Do you have, or have you ever had, any heart or blood pressure problems (heart or stroke)?"
                    className={classes.textField}
                    value={values.heartOrStroke}
                    margin="normal"
                    variant="outlined"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    fullWidth={true}
                  /> */}

                      <Box p={2}>

                        <FormControl
                          component="fieldset"
                          className={classes.formControl}
                          fullWidth={true}
                        >
                          <FormLabel component="legend">
                            Do you have, or have you ever had, any heart or blood pressure problems (heart or stroke)?
                    </FormLabel>
                          <RadioGroup
                            aria-label="heartOrStroke"
                            name="heartOrStroke"
                            value={values.heartOrStroke}
                            onChange={handleChange}
                            row={true}
                            onBlur={handleBlur}
                          >
                            <FormControlLabel
                              labelPlacement="end"
                              value="Yes"
                              control={<Radio />}
                              label="Yes"
                            />
                            <FormControlLabel
                              labelPlacement="end"
                              value="No"
                              control={<Radio />}
                              label="No"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>

                      {(values.heartOrStroke === 'Yes')
                        && (
                          <Grid item xs={12}>
                            <TextField
                              id="heartOrStrokeDescription"
                              label="Please Explain"
                              className={classes.textField}
                              margin="normal"
                              variant="outlined"
                              fullWidth={true}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.heartOrStrokeDescription}
                            />
                          </Grid>
                        )}

                      {/* <TextField
                    id="heartProblems"
                    label="Do you have a heart murmur, valve dysfunction (mitral valve prolapse or artificial heart valve) or have you ever had Rheumatic Fever?"
                    className={classes.textField}
                    value={values.heartProblems}
                    margin="normal"
                    variant="outlined"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    fullWidth={true}
                  /> */}

                      <Box p={2}>

                        <FormControl
                          component="fieldset"
                          className={classes.formControl}
                          fullWidth={true}
                        >
                          <FormLabel component="legend">
                            Do you have a heart murmur, valve dysfunction (mitral valve prolapse or artificial heart valve) or have you ever had Rheumatic Fever?
                    </FormLabel>
                          <RadioGroup
                            aria-label="heartProblems"
                            name="heartProblems"
                            value={values.heartProblems}
                            onChange={handleChange}
                            row={true}
                            onBlur={handleBlur}
                          >
                            <FormControlLabel
                              labelPlacement="end"
                              value="Yes"
                              control={<Radio />}
                              label="Yes"
                            />
                            <FormControlLabel
                              labelPlacement="end"
                              value="No"
                              control={<Radio />}
                              label="No"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>

                      {(values.heartProblems === 'Yes')
                        && (
                          <Grid item xs={12}>
                            <TextField
                              id="heartProblemsDescription"
                              label="Please Explain"
                              className={classes.textField}
                              margin="normal"
                              variant="outlined"
                              fullWidth={true}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.heartProblemsDescription}
                            />
                          </Grid>
                        )}

                      {/* <TextField
                    id="prosthetics"
                    label="Do you have any prosthetic or artificial joints (e.g. hip, knee)?"
                    className={classes.textField}
                    value={values.prosthetics}
                    margin="normal"
                    variant="outlined"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    fullWidth={true}
                  /> */}

                      <Box p={2}>

                        <FormControl
                          component="fieldset"
                          className={classes.formControl}
                          fullWidth={true}
                        >
                          <FormLabel component="legend">
                            Do you have any prosthetic or artificial joints (e.g. hip, knee)?
                    </FormLabel>
                          <RadioGroup
                            aria-label="prosthetics"
                            name="prosthetics"
                            value={values.prosthetics}
                            onChange={handleChange}
                            row={true}
                            onBlur={handleBlur}
                          >
                            <FormControlLabel
                              labelPlacement="end"
                              value="Yes"
                              control={<Radio />}
                              label="Yes"
                            />
                            <FormControlLabel
                              labelPlacement="end"
                              value="No"
                              control={<Radio />}
                              label="No"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>

                      {(values.prosthetics === 'Yes')
                        && (
                          <Grid item xs={12}>
                            <TextField
                              id="prostheticsDescription"
                              label="Please Explain"
                              className={classes.textField}
                              margin="normal"
                              variant="outlined"
                              fullWidth={true}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.prostheticsDescription}
                            />
                          </Grid>
                        )}

                      {/* <TextField
                    id="bloodDisorders"
                    label="Do you bleed excessively from a cut or injury, bruise easily or have any blood disorders?"
                    className={classes.textField}
                    value={values.bloodDisorders}
                    margin="normal"
                    variant="outlined"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    fullWidth={true}
                  /> */}

                      <Box p={2}>

                        <FormControl
                          component="fieldset"
                          className={classes.formControl}
                          fullWidth={true}
                        >
                          <FormLabel component="legend">
                            Do you bleed excessively from a cut or injury, bruise easily or have any blood disorders?
                    </FormLabel>
                          <RadioGroup
                            aria-label="bloodDisorders"
                            name="bloodDisorders"
                            value={values.bloodDisorders}
                            onChange={handleChange}
                            row={true}
                            onBlur={handleBlur}
                          >
                            <FormControlLabel
                              labelPlacement="end"
                              value="Yes"
                              control={<Radio />}
                              label="Yes"
                            />
                            <FormControlLabel
                              labelPlacement="end"
                              value="No"
                              control={<Radio />}
                              label="No"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>

                      {(values.bloodDisorders === 'Yes')
                        && (
                          <Grid item xs={12}>
                            <TextField
                              id="bloodDisordersDescription"
                              label="Please Explain"
                              className={classes.textField}
                              margin="normal"
                              variant="outlined"
                              fullWidth={true}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.bloodDisordersDescription}
                            />
                          </Grid>
                        )}

                      {/* <TextField
                    id="hospitalized"
                    label="Have you ever been hospitalized for any illness or operations?"
                    className={classes.textField}
                    value={values.hospitalized}
                    margin="normal"
                    variant="outlined"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    fullWidth={true}
                  /> */}

                      <Box p={2}>

                        <FormControl
                          component="fieldset"
                          className={classes.formControl}
                          fullWidth={true}
                        >
                          <FormLabel component="legend">
                            Have you ever been hospitalized for any illness or operations?
                    </FormLabel>
                          <RadioGroup
                            aria-label="hospitalized"
                            name="hospitalized"
                            value={values.hospitalized}
                            onChange={handleChange}
                            row={true}
                            onBlur={handleBlur}
                          >
                            <FormControlLabel
                              labelPlacement="end"
                              value="Yes"
                              control={<Radio />}
                              label="Yes"
                            />
                            <FormControlLabel
                              labelPlacement="end"
                              value="No"
                              control={<Radio />}
                              label="No"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>

                      {(values.hospitalized === 'Yes')
                        && (
                          <Grid item xs={12}>
                            <TextField
                              id="hospitalizedDescription"
                              label="Please Explain"
                              className={classes.textField}
                              margin="normal"
                              variant="outlined"
                              fullWidth={true}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.hospitalizedDescription}
                            />
                          </Grid>
                        )}

                      <Box p={2}>
                        <FormControl fullWidth={true}>
                          <FormGroup>
                            <FormLabel component="legend">
                              Indicate which of the following you presently have, or ever had: (Please check all that apply)
                      </FormLabel>
                            <Grid container>

                              <Grid item xs={12} md={4}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={values.diseases['asthma']}
                                      onChange={handleChange}
                                      name="diseases.asthma"
                                    />
                                  }
                                  label="Asthma"
                                />
                              </Grid>

                              <Grid item xs={12} md={4}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={values.diseases['bronchitis']}
                                      onChange={handleChange}
                                      name="diseases.bronchitis"
                                    />
                                  }
                                  label="Bronchitis"
                                />
                              </Grid>

                              <Grid item xs={12} md={4}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={values.diseases['emphysema']}
                                      onChange={handleChange}
                                      name="diseases.emphysema"
                                    />
                                  }
                                  label="Emphysema"
                                />
                              </Grid>

                              <Grid item xs={12} md={4}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={values.diseases['lung-disease']}
                                      onChange={handleChange}
                                      name="diseases.lung-disease"
                                    />
                                  }
                                  label="Lung Disease"
                                />
                              </Grid>

                              <Grid item xs={12} md={4}>

                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={values.diseases['hepatitis-b-or-c']}
                                      onChange={handleChange}
                                      name="diseases.hepatitis-b-or-c"
                                    />
                                  }
                                  label="Hepatitis B or C"
                                />

                              </Grid>


                              <Grid item xs={12} md={4}>

                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={values.diseases['jaundice']}
                                      onChange={handleChange}
                                      name="diseases.jaundice"
                                    />
                                  }
                                  label="Jaundice"
                                />

                              </Grid>

                              <Grid item xs={12} md={4}>

                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={values.diseases['liver-disease']}
                                      onChange={handleChange}
                                      name="diseases.liver-disease"
                                    />
                                  }
                                  label="Liver Disease"
                                />

                              </Grid>

                              <Grid item xs={12} md={4}>

                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={values.diseases['tuberculosis']}
                                      onChange={handleChange}
                                      name="diseases.tuberculosis"
                                    />
                                  }
                                  label="Tuberculosis"
                                />

                              </Grid>

                              <Grid item xs={12} md={4}>

                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={values.diseases['diabetes']}
                                      onChange={handleChange}
                                      name="diseases.diabetes"
                                    />
                                  }
                                  label="Diabetes"
                                />

                              </Grid>

                              <Grid item xs={12} md={4}>

                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={values.diseases['kidney-disease']}
                                      onChange={handleChange}
                                      name="diseases.kidney-disease"
                                    />
                                  }
                                  label="Kidney Disease"
                                />

                              </Grid>

                              <Grid item xs={12} md={4}>

                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={values.diseases['thyroid-disease']}
                                      onChange={handleChange}
                                      name="diseases.thyroid-disease"
                                    />
                                  }
                                  label="Thyroid Disease"
                                />

                              </Grid>

                              <Grid item xs={12} md={4}>

                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={values.diseases['glandular-disorders']}
                                      onChange={handleChange}
                                      name="diseases.glandular-disorders"
                                    />
                                  }
                                  label="Glandular Disorders"
                                />

                              </Grid>

                              <Grid item xs={12} md={4}>

                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={
                                        values.diseases[
                                        'organ-transplant-medical-implant'
                                        ]
                                      }
                                      onChange={handleChange}
                                      name="diseases.organ-transplant-medical-implant"
                                    />
                                  }
                                  label="Organ Transplant / Medical Implant"
                                />

                              </Grid>

                              <Grid item xs={12} md={4}>

                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={
                                        values.diseases['stomach-or-intestinal-problems']
                                      }
                                      onChange={handleChange}
                                      name="diseases.stomach-or-intestinal-problems"
                                    />
                                  }
                                  label="Stomach or Intestinal Problems"
                                />

                              </Grid>

                              <Grid item xs={12} md={4}>

                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={values.diseases['ulcers']}
                                      onChange={handleChange}
                                      name="diseases.ulcers"
                                    />
                                  }
                                  label="Ulcers"
                                />

                              </Grid>

                              <Grid item xs={12} md={4}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={values.diseases['epilepsy-or-seizures']}
                                      onChange={handleChange}
                                      name="diseases.epilepsy-or-seizures"
                                    />
                                  }
                                  label="Epilepsy or Seizures"
                                />


                              </Grid>

                              <Grid item xs={12} md={4}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={values.diseases['hiv-positive']}
                                      onChange={handleChange}
                                      name="diseases.hiv-positive"
                                    />
                                  }
                                  label="HIV+"
                                />
                              </Grid>

                              <Grid item xs={12} md={4}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={values.diseases['nervous-disorders']}
                                      onChange={handleChange}
                                      name="diseases.nervous-disorders"
                                    />
                                  }
                                  label="Nervous Disorders"
                                />
                              </Grid>

                            </Grid>
                          </FormGroup>
                        </FormControl>
                      </Box>

                      {/* <TextField
                    id="anyOtherConditions"
                    label="Do you currently have, or ever had in the past, any disease, condition or problem not listed above?"
                    className={classes.textField}
                    value={values.anyOtherConditions}
                    margin="normal"
                    variant="outlined"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    fullWidth={true}
                  /> */}

                      <Box p={2}>

                        <FormControl
                          component="fieldset"
                          className={classes.formControl}
                          fullWidth={true}
                        >
                          <FormLabel component="legend">
                            Do you currently have, or ever had in the past, any disease, condition or problem not listed above?
                    </FormLabel>
                          <RadioGroup
                            aria-label="pastAndExistingDisorders"
                            name="pastAndExistingDisorders"
                            value={values.pastAndExistingDisorders}
                            onChange={handleChange}
                            row={true}
                            onBlur={handleBlur}
                          >
                            <FormControlLabel
                              labelPlacement="end"
                              value="Yes"
                              control={<Radio />}
                              label="Yes"
                            />
                            <FormControlLabel
                              labelPlacement="end"
                              value="No"
                              control={<Radio />}
                              label="No"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>

                      {(values.pastAndExistingDisorders === 'Yes')
                        && (
                          <Grid item xs={12}>
                            <TextField
                              id="pastAndExistingDisordersDescription"
                              label="Please Explain"
                              className={classes.textField}
                              margin="normal"
                              variant="outlined"
                              fullWidth={true}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.pastAndExistingDisordersDescription}
                            />
                          </Grid>
                        )}

                      {/* <TextField
                    id="heriditaryIssues"
                    label="Are there any diseases or medical problems that run in your family?"
                    className={classes.textField}
                    value={values.heriditaryIssues}
                    margin="normal"
                    variant="outlined"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    fullWidth={true}
                  /> */}

                      <Box p={2}>

                        <FormControl
                          component="fieldset"
                          className={classes.formControl}
                          fullWidth={true}
                        >
                          <FormLabel component="legend">
                            Are there any diseases or medical problems that run in your family?
                    </FormLabel>
                          <RadioGroup
                            aria-label="heriditaryIssues"
                            name="heriditaryIssues"
                            value={values.heriditaryIssues}
                            onChange={handleChange}
                            row={true}
                            onBlur={handleBlur}
                          >
                            <FormControlLabel
                              labelPlacement="end"
                              value="Yes"
                              control={<Radio />}
                              label="Yes"
                            />
                            <FormControlLabel
                              labelPlacement="end"
                              value="No"
                              control={<Radio />}
                              label="No"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>

                      {(values.heriditaryIssues === 'Yes')
                        && (
                          <Grid item xs={12}>
                            <TextField
                              id="heriditaryIssuesDescription"
                              label="Please Explain"
                              className={classes.textField}
                              margin="normal"
                              variant="outlined"
                              fullWidth={true}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.heriditaryIssuesDescription}
                            />
                          </Grid>
                        )}

                      {/* <TextField
                    id="smoker"
                    label="Are you a smoker?"
                    className={classes.textField}
                    value={values.smoker}
                    margin="normal"
                    variant="outlined"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    fullWidth={true}
                  /> */}

                      <Box p={2}>

                        <FormControl
                          component="fieldset"
                          className={classes.formControl}
                          fullWidth={true}
                        >
                          <FormLabel component="legend">
                            Are you a smoker?
                    </FormLabel>
                          <RadioGroup
                            aria-label="smoker"
                            name="smoker"
                            value={values.smoker}
                            onChange={handleChange}
                            row={true}
                            onBlur={handleBlur}
                          >
                            <FormControlLabel
                              labelPlacement="end"
                              value="Yes"
                              control={<Radio />}
                              label="Yes"
                            />
                            <FormControlLabel
                              labelPlacement="end"
                              value="No"
                              control={<Radio />}
                              label="No"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>

                      {(values.smoker === 'Yes')
                        && (
                          <Grid item xs={12}>
                            <TextField
                              id="smokerDescription"
                              label="Please Explain"
                              className={classes.textField}
                              margin="normal"
                              variant="outlined"
                              fullWidth={true}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.smokerDescription}
                            />
                          </Grid>
                        )}

                      {/* <TextField
                    id="breastFeeding"
                    label="Are there any diseases or medical problems that run in your family?"
                    className={classes.textField}
                    value={values.breastfeeding}
                    margin="normal"
                    variant="outlined"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    fullWidth={true}
                  /> */}

                      <Box p={2}>

                        <FormControl
                          component="fieldset"
                          className={classes.formControl}
                          fullWidth={true}
                        >
                          <FormLabel component="legend">
                            WOMEN ONLY: Are you pregnant or breast feeding?
                    </FormLabel>
                          <RadioGroup
                            aria-label="breastfeeding"
                            name="breastfeeding"
                            value={values.breastfeeding}
                            onChange={handleChange}
                            row={true}
                            onBlur={handleBlur}
                          >
                            <FormControlLabel
                              labelPlacement="end"
                              value="Yes"
                              control={<Radio />}
                              label="Yes"
                            />
                            <FormControlLabel
                              labelPlacement="end"
                              value="No"
                              control={<Radio />}
                              label="No"
                            />
                            <FormControlLabel
                              labelPlacement="end"
                              value="Not Sure"
                              control={<Radio />}
                              label="Not Sure"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>

                      {(values.breastfeeding === 'Yes' || values.breastfeeding === 'Not Sure')
                        && (
                          <Grid item xs={12}>
                            <TextField
                              id="breastFeedingDescription"
                              label="Please Explain"
                              className={classes.textField}
                              margin="normal"
                              variant="outlined"
                              fullWidth={true}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.breastFeedingDescription}
                            />
                          </Grid>
                        )}


                      <Box p={2}>
                        <Typography>
                          By clicking the submit button below, I certify that I have
                          provided an accurate and complete personal and
                          medical/dental history to the best of my knowledge. All
                          information is confidential and is accessed only via a
                          secure, encryptped interface. Should there be any change in
                          my personal or health status in the future, I will advise
                          this dental office. I authorize the dentist to perform
                          diagnostic procedures as may be required to determine
                          necessary treatment. I understand that information provided
                          from or to my medical doctor or another health care provider
                          may be necessary. I understand that responsibility for
                          payment of the dental services for myself and my dependents
                          is mine, and I assume responsibility for fees associated
                      with these services. I understand that <b>48 hours</b>{' '}
                      notice is required for changing or cancellation of my
                      appointments, otherwise there is <b>$70</b> charge per hour
                      that may be applied.
                    </Typography>
                      </Box>
                      <Box p={2}>

                        <BlueButton
                          onClick={(e) => {
                            e.preventDefault()
                            handleSubmit(e)
                          }}
                          disabled={submitStatus.status}
                        >
                          Submit&nbsp;&nbsp;
                      {submitStatus.status && <CircularProgress color="inherit" size={25} thickness={2} />}
                        </BlueButton>
                      </Box>
                    </Grid>
                    <ErrorFocus />
                  </FormWrapper>
                </Container>
              </Box>
            </ContentWrapper>
          )
        }}
      />
    </Layout>
  )
})

/*
    I, the undersigned, certify that I have provided an accurate and
    complete personal and medical - dental history and have not
    knowingly omitted any information. I have had the opportunity to
    ask questions and receive answers to any questions regarding my
    medical - dental history. Should there be any change in my
    health status in the future, I will advise this dental office. I
    authorize the dentist to perform diagnostic procedures as may be
    required to determine necessary treatment. I understand that
    information provided from or to my medical doctor or another
    health care provider may be necessary. I understand that
    responsibility for payment of the dental services for myself and
    my dependents is mine, and I assume responsibility for fees
    associated with these services.I understand that 48 hours notice
    is required for changing or cancellation of my appointments,
    otherwise there is $50 charge per hour that may be apply.
*/
